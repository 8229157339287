import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-above-the-fold',
  standalone: true,
  imports: [],
  templateUrl: './above-the-fold.component.html',
  styleUrl: './above-the-fold.component.scss'
})
export class AboveTheFoldComponent {

  // arrowImages: string[] = [
  //   'assets/images/images_above_the_fold/arrow1.png',
  //   'assets/images/images_above_the_fold/arrow2.png',
  //   'assets/images/images_above_the_fold/arrow3.png',
  //   'assets/images/images_above_the_fold/arrow4.png',
  //   'assets/images/images_above_the_fold/arrow5.png',
  //   'assets/images/images_above_the_fold/arrow6.png',
  //   'assets/images/images_above_the_fold/arrow7.png'
  // ];

  // currentImageIndex: number = 0;

  // ngOnInit(): void {
  //   this.startImageRotation();
  // }

  // startImageRotation(): void {
  //   setInterval(() => {
  //     this.currentImageIndex = (this.currentImageIndex + 1) % this.arrowImages.length;
  //   }, 125);
  // }
}
