<div class="background-div">
    <div class="about-me-wrapper">
        <h3 class="fontSyne">
            About me
        </h3>
        <div class="about-me-box">
            <div class="about-me-box-left">
                <p class="fontOverpass">
                    I am a Front-end deverloper from Munich, Germany. I help designers and companies to bring  their ideas to real life.  Creativity and logical thinking are skills that I have developed to connect ideas into quality products.
                </p>
                <p class="fontOverpass">
                    If you think I’d be a good match for your projects, contact me!
                </p>
                <div class="about-me-left-big-box-bottom">
                    <div class="about-me-left-box-bottom">
                        <img src="assets/images/images_about_me/house.png" alt="">
                        <p>
                            Based in Munich
                        </p>
                    </div>
                    <div class="about-me-left-box-bottom">
                        <img src="assets/images/images_about_me/laptop_chromebook.png" alt="">
                        <p>
                            Open to work Remote
                        </p>
                    </div>
                </div>
                <div class="about-me-button-box">
                    <button class="fontOverpass">
                        Lets Talk
                    </button>
                </div>
               
            </div>
            <div class="about-me-box-right">
                <img src="assets/images/images_about_me/Mask group.png" alt="">
            </div>
        </div>
        <div class="about-me-box-finish">
            <img src="assets/images/images_about_me/arrowbottom.png" alt="">
        </div>
    </div>
</div>