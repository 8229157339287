<div class="above-the-fold-wrapper">
    <div class="above-the-fold-box">
        <div class="above-the-fold-überschrift fontSyne">
            <h3 class="margin-top-h3">
                Frontend
            </h3>
            <h3>
                Developer
            </h3>
        </div>
        <div class="above-the-fold-initialien">
            <span class="horizontal-line">

            </span>
            <span class="initialien fontOverpass">
                Maximilian Bendel
            </span>
        </div>

        <div class="email-and-scroll-down-box">
            <div class="email-position-absolute">
                max.bendel1996&#64;gmail.com
            </div>
            
            <div class="position-abosulte-right">
                <div class="scroll-down-position-absolute">
                </div>
                <div class="scroll-down-position-absolute-span">
                    <span>Scroll</span>
                    <span>Down</span>
                </div>
            </div>
        </div>
    </div>
</div>
